@tailwind base;
@tailwind components;
@tailwind utilities;

//sm (640px)	max-width: 640px;
// md (768px)	max-width: 768px;
// lg (1024px)	max-width: 1024px;

@import './style/color';
@import './style/fonts';
@import './member/Register.scss';

.font-label-size-16 {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 16px;
  color: $dark-grey;
}

.font-label-size-18 {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 18px;
  color: $dark-grey;
}

.font-input-size-16 {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 16px;
  color: $dark-grey;
  line-height: 21px;
}

.font-red {
  color: $red;
}

.font-under-line {
  text-decoration: underline;
}

button:focus {
  outline: none;
}

.header {
  background: url('./assets/layout/header.svg') no-repeat;
  width: 100%;
  background-size: cover;
  padding-bottom: 18%;
  position: fixed;
}

.wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.info {
  background: url('./assets/layout/info.svg') no-repeat;
  width: 18px;
  height: 18px;
  background-size: cover;
  position: absolute;
  top: 11%;
  right: 2%;
}

.light-blue-bg {
  background-color: $light-blue-bg;
}

.modal-container {
  background-color: #00000099;
}

// Menu layout
html {
  height: 100%;
}
.shiba {
  background: url('./assets/layout/Mini_Shiba/Mini_Shiba.png');
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}

.rope {
  background: url('./assets/layout/rope.svg') no-repeat center center;
  background-size: cover;
}

.btn-menu-item {
  background-color: $light-blue;
  box-shadow: 0px 5px $dark-blue;
  width: 70%;
  padding: 5% 3%;
  white-space: pre-line;
  font-family: 'ThaiSansNeue-UltraBold';
  color: white;
}

.wrapper-menu {
  width: 100%;
  background: rgba(224, 237, 255, 1.0);
  background: -webkit-radial-gradient(center, rgba(224, 237, 255, 1.0), rgba(0, 84, 164, 1.0));
  background: -moz-radial-gradient(center, rgba(224, 237, 255, 1.0), rgba(0, 84, 164, 1.0));
  background: radial-gradient(ellipse at center, rgba(224, 237, 255, 1.0), rgba(0, 84, 164, 1.0));
}

.image-profile {
  width: 12.5%;
  float: right;
}

.point-bage-wrapper {
  position: relative;
  width: 95%;
  margin-top: -15%;
  background: $medium-blue;
  border: 1px solid #fff;
  height: 14px;
  border-radius: 10px;
}

.tag {
  display: inline-block;
  width: 12px;
  height: 14px;
}

.tag-text {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  font-family: 'ThaiSansNeue-UltraBold';
}

.exam-title {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 16pt;
  color: $dark-blue;
}

.exam-multiply {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 18pt;
  color: $red;
}

.icon-close-modal {
  float: right;
  background: url('./assets/layout/exam/close.svg') no-repeat top;
}

// Loader
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
