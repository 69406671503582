@import '../style/color';

.register-form {
  select,
  input {
    box-shadow: none;
    border-color: $light-grey;
    height: 45px;
    border-radius: 6px;
  }
  input[type='checkbox'] {
    height: 33px;
    width: 33px;
    border-radius: 9px;
  }
}
.btn-menu {
  border: 0;
  width: 150px;
  height: 134px;
  background-size: cover;
}

.label-header {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 21px;
  color: $dark-blue;
}

.text {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 18px;
  color: $dark-grey;
}

.text-ref {
  font-family: 'ThaiSansNeue-Bold';
  font-size: 18px;
  color: $medium-blue;
}

.btn-menu.btn-staff {
  background-image: url('../assets/register/staff-btn.png');
}

.btn-menu.btn-staff-active {
  background-image: url('../assets/register/staff-active-btn.png');
}

.btn-menu.btn-veterinary {
  background-image: url('../assets/register/veterinary-btn.png');
}

.btn-menu.btn-veterinary-active {
  background-image: url('../assets/register/veterinary-active-btn.png');
}

.btn-menu.btn-std-veterinary {
  background-image: url('../assets/register/std-veterinary-btn.png');
}

.btn-menu.btn-std-veterinary-active {
  background-image: url('../assets/register/std-veterinary-active-btn.png');
}

.btn-register {
  background-color: $light-blue;
  box-shadow: 0px 5px $dark-blue;
  border-radius: 14px;
  width: 256px;
  height: 54px;
  font-family: 'ThaiSansNeue-ExtraBold';
  font-size: 30px;
  color: white;
}

.term-checkbox-size {
  width: 33px;
  height: 33px;
  border-radius: 5px;
}

.register-type-form-btn {
  position: absolute;
  bottom: 30px;
  width: 100%;
}