@import '../../style/_color.scss';
@import "../../style/fonts";

.header-banner {
    background: url('../../assets/layout/profile/profile.png') no-repeat right;
    background-size: cover;
    max-height:110px;
    max-width:209px;
    width: 100%;
    height: 100%;
    z-index: 10;
    margin-bottom: -3rem;
    margin-top: 3.5rem;
}

.btn-size {
    height: 50px;
    width: 150px;
}

.btn-lesson{
    background: url('../../assets/layout/lesson_bt/lesson_bt@2x.png') no-repeat center;
    background-size:100%;

}

.btn-redeem {
    background: url('../../assets/layout/redeem_bt/redeem_bt@2x.png') no-repeat center;
    background-size:100%; 
}

.btn-profile {
    background: url('../../assets/layout/profile/profile_bt/profile_bt@2x.png') no-repeat center;
    background-size:100%; 
}

.info-bg{
    background: url('../../assets/layout/profile/info_bg.svg') no-repeat top;
    background-size:100%; 
    max-height: 472px;
    max-width: 333px;
    width: 100%;
    height: 100%;

}

.display-name {
    color: #FFFFFF;
    font-family: 'ThaiSansNeue-Bold';
    font-size: 21px;
}