.btn-close {
  //background-color: #babcbe;
  background: #898989;
  background: -webkit-linear-gradient(163deg, #898989, #BABCBE);
  background: -moz-linear-gradient(163deg, #898989, #BABCBE);
  background: linear-gradient(163deg, #898989, #BABCBE);
  box-shadow: 0px 5px #898989;
  padding: 3.5%;
  font-family: 'ThaiSansNeue-UltraBold';
  font-size: 20pt;
  color: white;
}

.btn-submit {
  background-color: #0255a3;
  box-shadow: 0px 5px #023672;
  padding: 3.5%;
  font-family: 'ThaiSansNeue-UltraBold';
  font-size: 20pt;
  color: white;
}

.redeem-modal-border {
  border: 0.25rem solid #265ea8;
  border-radius: 1.25rem;
  height: 16rem;
}

.redeem-modal-content {
  background-image: linear-gradient(#01518a, #00488d, #00488d);
  border-radius: 1.25rem;
  height: 100%;
}

h1 {
  font-size: 32px;
  margin-left: 10%;
  margin-right: 10%;
  line-height: 1em;
  font-family: 'ThaiSansNeue-ExtraBold';
}
