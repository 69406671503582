// 100
@font-face {
  font-family: 'ThaiSansNeue-UltraLight';
  src: local('ThaiSansNeue-UltraLight'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-UltraLight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-UltraLightItal';
  src: local('ThaiSansNeue-UltraLightItal'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-UltraLightItal.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

// 200 extra light
@font-face {
  font-family: 'ThaiSansNeue-ExtraLight';
  src: local('ThaiSansNeue-ExtraLight'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-ExtraLightItalic';
  src: local('ThaiSansNeue-ExtraLightItalic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

// 300
@font-face {
  font-family: 'ThaiSansNeue-Light';
  src: local('ThaiSansNeue-Light'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-LightItalic';
  src: local('ThaiSansNeue-LightItalic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

// regular/400
@font-face {
  font-family: 'ThaiSansNeue-Regular';
  src: local('ThaiSansNeue-Regular'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-Italic';
  src: local('ThaiSansNeue-Italic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

// 500 semi-bold
@font-face {
  font-family: 'ThaiSansNeue-SemiBold';
  src: local('ThaiSansNeue-SemiBold'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-SemiBold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-SemiBoldItalic';
  src: local('ThaiSansNeue-SemiBoldItalic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-SemiBoldItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

// 600 bold
@font-face {
  font-family: 'ThaiSansNeue-Bold';
  src: local('ThaiSansNeue-Bold'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-BoldItalic';
  src: local('ThaiSansNeue-BoldItalic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-BoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

// 700 ExtraBold
@font-face {
  font-family: 'ThaiSansNeue-ExtraBold';
  src: local('ThaiSansNeue-ExtraBold'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-ExtraBoldItalic';
  src: local('ThaiSansNeue-ExtraBoldItalic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-ExtraBoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

// 800 ExtraBold
@font-face {
  font-family: 'ThaiSansNeue-UltraBold';
  src: local('ThaiSansNeue-UltraBold'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-UltraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-UltraBoldItali';
  src: local('ThaiSansNeue-UltraBoldItali'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-UltraBoldItali.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

// black 900
@font-face {
  font-family: 'ThaiSansNeue-Black';
  src: local('ThaiSansNeue-Black'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ThaiSansNeue-BlackItalic';
  src: local('ThaiSansNeue-BlackItalic'),
    url('~style/fonts/ThaiSans-Neue-v1.0/ThaiSansNeue-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

* {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  font-family: 'ThaiSansNeue-Regular';
  font-size: 12px;
}