@import '../style/_color.scss';

.coupon-detail {
  color: $dark-blue;
}

.header-label-top {
  border-color: $dark-blue;
}

.header-label-bottom {
  border-color: $dark-blue;
  background-color: $dark-blue;
}
