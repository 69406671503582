@import "../../style/_color.scss";

.info-graphic {
  height: 85%;
  overflow: scroll;
  .header-label {
    border-color: $dark-blue;
  }
}

.see-more-area {
  background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.5));
}

.button {
  border-radius: 21px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  line-height: 12px;
  &.active {
    background-color: #0054A4;
  }
  &.in-active {
    color: #74797A;
    background-color: #D0D0D0;
  }
}
