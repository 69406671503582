@import "src/style/color";

.exam-border {
  background-color: $medium-blue;
  border: 15px solid $medium-blue;
  border-radius: 15px;
  &.complete {
    background-color: #FFC233;
    border: 15px solid #FFC233;
  }
  .exam {
    border: 15px solid #FFF;
    border-radius: 10px;
    background-color: #FFF;
    &.complete {
      border: 15px solid #FFEF94;
      background-color: #FFEF94;
    }
    .header-question {
      color: $dark-blue;
    }
  }
}

.exam-radio {
  color: $dark-blue;
  border: 0.35em solid $light-blue;
}
