@import '../../style/color';
@import '../../style/fonts';

.modal-header {
  background: url('../../assets/layout/exam/star_popup.svg') no-repeat top;
  margin-bottom: -2.5rem;
  height: 6rem;
  z-index: 99;
}
.modal-content {
  border-radius: 2rem;
  border: 1.5rem solid #eb3444;
}
.text-description {
  color: #023672;
  font-weight: bold;
  font-size: 40pt;
}
.time-result {
  display: flex;
}
.time-text {
  font-size: 35pt;
  font-weight: bold;
}
.text-decision {
  color: #023672;
  font-weight: bold;
  font-size: 24pt;
}
.btn-send-result {
  background-color: $light-blue;
  box-shadow: 0px 5px $dark-blue;
  width: 80%;
  padding: 3.5%;
  font-family: 'ThaiSansNeue-UltraBold';
  color: white;
}
.btn-refresh {
  background-color: $red;
  box-shadow: 0px 5px $dark-red;
  width: 80%;
  padding: 3.5%;
  font-family: 'ThaiSansNeue-UltraBold';
  color: white;
}
