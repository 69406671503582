$light-blue-bg: #d1e4f5;
$light-blue: #0255a3;
$blue:#22539e;
$dark-blue: #023672;
$heavy-blue: #265EA8;
$medium-blue:#0054A4;
$light-grey: #d0d0d0;
$grey:#898989;
$dark-grey: #4e4e4e;
$red: #d6182f;
$dark-red: #ab0c1c;
