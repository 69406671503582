
@import "../style/color";
@import "../style/fonts";

.label-header-coupon {
    font-family: 'ThaiSansNeue-UltraBold';
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 1.68px;
}

.font-heavy-blue {
    color: $heavy-blue;
}

.label-coupon {
    font-family: 'ThaiSansNeue-Bold';
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1.5px;
    &.point {
        font-size: 32px;
        line-height: 2.5rem;
    }
    &.label-header-point  {
        font-size: 21px;
        letter-spacing: 3px;

    }
   
}

label-coupon-button {
    height: 28px;
}

.label-size-16 {
    font-family: 'ThaiSansNeue-UltraBold';
    font-size: 16px;
}

.font-grey {
    color: $grey;
}


.bg-color-blue {
    background-color: #0054A4;
}


