@import '../../style/color';
@import '../../style/fonts';

.exam-point-modal-container {
  background-color: #00000099;

  & .exam-point-modal-close {
    background: red;
    float: right;
    background: url('../../assets/layout/exam/close.svg') no-repeat top;
  }

  & .exam-point-modal-header {
    background: url('../../assets/layout/exam/ribbon.svg') no-repeat top;
    margin-bottom: -4rem;
    height: 6rem;
    z-index: 99;
    text-align: center;

    > .exam-point-text-history {
      font-size: 20pt;
      font-weight: bold;
      color: white;
    }
  }

  & .exam-point-modal-content {
    border-radius: 1rem;
    border: 0.25rem solid #265ea8;
    background: #00488d;
    height: 530px;
    max-height: 530px;

    > .exam-point-modal-box {
      background: white;
      display: inline-block;
      width: 85%;
      margin: 0.5rem;
      padding: 1rem;
      border-radius: 0.5rem;
    }

    > .exam-point-modal-box:nth-child(1) {
      margin-top: 3rem;
    }

    > .exam-point-modal-box:last-child {
      margin-bottom: 2rem;
    }

    > .exam-point-modal-box-coupon {
      background: #d9eef5;
      border: 2px solid red;
      display: inline-block;
      width: 85%;
      margin: 0.5rem;
      border-radius: 0.5rem;
    }
  }
}

.exam-title {
  color: #265ea8;
  font-size: 14pt;
  font-family: 'ThaiSansNeue-Bold';
}

.exam-date {
  color: #265ea8;
  font-size: 14pt;
  font-family: 'ThaiSansNeue-Regular';
}

.exam-time {
  color: #265ea8;
  font-size: 14pt;
  font-family: 'ThaiSansNeue-Regular';
}

.exam-point {
  color: #265ea8;
  font-size: 14pt;
  font-family: 'ThaiSansNeue-Regular';
}

.exam-title-content {
  width: 80%;
}

.exam-receive-point-content {
  width: 20%;
}

.exam-receive-point {
  color: #265ea8;
  font-size: 18pt;
  font-family: 'ThaiSansNeue-Bold';
}

.image-style {
  height: 1rem;
  margin-top: 0.75rem;
}
