@import "../../style/color";
@import "../../style/fonts";

.star {
    background: url('../../assets/layout/exam-info/star.svg') no-repeat right;
    background-size: cover;
}

.info-container {
    position: relative;
}

.edit-icon-link {
    position: absolute;
    top: 5px;
    right: 5px;
}

.edit-icon {
    height: 27px;
}

.label-header-member {
    font-family: 'ThaiSansNeue-UltraBold';
    font-size: 21px;
    color: #FFF;
    letter-spacing: 1.2pt;
    line-height: 26px;
}

.shadow-panel{
    position: relative;
    background-color:#164C90;
    border-radius: 14pt;
    border: 4pt solid #023672;
}

.label-member {
    font-family: 'ThaiSansNeue-Bold';
    font-size: 20px;
    color: #FFF;
    line-height: 30px;
}