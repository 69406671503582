@import '../../style/_color.scss';

.header-bar {
  height: 110px;
  .header-clock {
    background: url('../../assets/layout/exam/clock.svg') no-repeat right;
    height: 80px;
    width: 125px;
    .text-clock {
      font-size: 28px;
    }
  }
}

.exam-footer {
  background: url('../../assets/layout/exam/next_bg.png') no-repeat bottom;
  background-size: 100%;
}
