.video-container {
  border-bottom: 10px solid #898989;
  border-top: 10px solid #898989;
  border-right: 14px solid #d0d0d0;
  border-left: 14px solid #d0d0d0;
}

@media screen and (max-width: 480px) {
  .video-container {
    height: 170px;
  }
}

@media screen and (min-width: 481px) {
  .video-container {
    height: 400px;
  }
}
