@import '../../style/color';

.edit-member-info-form {
    .form {
        min-height: 80vh;
        min-height: -webkit-fill-available;
    }

    select,
    input {
        box-shadow: none;
        border-color: $light-grey;
        height: 45px;
        border-radius: 6px;
    }

    input[type="checkbox"] {
        height: 33px;
        border-radius: 9px;
    }
}

.btn-submit {
    background-color: #0255a3;
    box-shadow: 0px 5px #023672;
    border-radius: 14px;
    width: 256px;
    height: 54px;
    font-family: 'ThaiSansNeue-ExtraBold';
    font-size: 30px;
    color: white;
    padding: 0;
}