

.menu {
   width: 168px;
   height: 187px;
   
   &.menu-1 {
      background: url('../../assets/menu/menu1/menu1@2x.png') no-repeat bottom;
      background-size: cover;
   }
   &.menu-2 {
      background: url('../../assets/menu/menu2/menu2@2x.png') no-repeat bottom;
      background-size: cover;
   }
   &.menu-3 {
      background: url('../../assets/menu/menu3/menu3@2x.png') no-repeat bottom;
      background-size: cover;
   }
   &.menu-4 {
      background: url('../../assets/menu/menu4/menu4@2x.png') no-repeat bottom;
      background-size: cover;
   }
}
