@import 'src/style/color';

.header-text {
  font-size: 18px;
  font-weight: bold;
}

.list-item {
  list-style-type: initial;
  list-style-position: inside;
}
