@import '../../style/_color.scss';

.exam-info-footer {
  height: 20%;
  width: 100%;
  &.active {
    background: url('../../assets/layout/exam-info/start_bg_active.png')
      no-repeat bottom;
    background-size: 100%;
  }
  &.in-active {
    background: url('../../assets/layout/exam-info/start_bg_in_active.png')
      no-repeat bottom;
    background-size: 100%;
  }
}

.info-footer {
  width: 100%;
  &.active {
    background: url('../../assets/layout/exam-info/info_bg.png') no-repeat
      bottom;
    background-size: 100%;
  }
  &.in-active {
    background: url('../../assets/layout/exam-info/info_bg_disabled.png')
      no-repeat bottom;
    background-size: 100%;
  }
}
