@import '../../style/color';
@import '../../style/fonts';

.exam-point-modal-container {
  background-color: #00000099;

  & .exam-point-modal-close {
    float: right;
    background: url('../../assets/layout/exam/close.svg') no-repeat top;
  }

  & .exam-point-modal-header {
    background: url('../../assets/layout/exam/ribbon.svg') no-repeat top;
    margin-bottom: -4rem;
    height: 6rem;
    z-index: 99;
    text-align: center;

    > .exam-point-text-history {
      font-size: 20pt;
      font-family: 'ThaiSansNeue-UltraBold';
      color: white;
    }
  }

  & .exam-point-modal-content {
    border-radius: 1rem;
    border: 0.25rem solid #265ea8;
    background: #00488d;

    > .exam-point-modal-box {
      background: white;
      display: inline-block;
      width: 85%;
      margin: 0.25rem;
      padding: 0.25rem;
      border-radius: 0.5rem;
    }

    > .exam-point-modal-box:nth-child(1) {
      margin-top: 3rem;
    }

    > .exam-point-modal-box:last-child {
      margin-bottom: 1rem;
    }
  }
}

.owner {
  background-color: #fff3b2 !important;
  border: 1pt solid #ff942d;
}

.top-leader {
  background: white;
  width: 25%;
}

.text-full-name-leader {
  font-size: 10pt;
  font-family: 'ThaiSansNeue-Regular';
  color: #10375c;
}

.text-full-name {
  font-size: 14pt;
  font-family: 'ThaiSansNeue-Regular';
  color: #10375c;
}

.text-point {
  font-size: 18pt;
  font-family: 'ThaiSansNeue-Bold';
  color: #265ea8;
}

.text-point-leader {
  font-size: 14pt;
  font-family: 'ThaiSansNeue-Bold';
  color: #265ea8;
}

.text-time-leader {
  font-size: 13pt;
  font-family: 'ThaiSansNeue-Regular';
  color: #265ea8;
}

.text-time {
  font-size: 13pt;
  font-family: 'ThaiSansNeue-Regular';
  color: #10375c;
}

.text-rank {
  font-size: 19pt;
  color: #10375c;
  font-family: 'ThaiSansNeue-Regular';
}

.border-radius-4px {
  border-radius: 4px;
}

.star-style {
  margin-left: -1rem;
}
